import React from 'react'
import { connect } from 'react-redux'
import ChatSDK from "@livechat/chat-sdk";
import { accountsSdk } from "@livechat/accounts-sdk";
import { CLIENT_ID, LICENSE_ID } from '../config'
import * as CustomerSDK from '@livechat/customer-sdk'
import { getQualifyQuestionAnswerApi,updatePrechatData } from '../redux/prechat/Action'
import { getTearSheet, getsnoozeCampaign } from "../service/api.service";
import { CANDIDATE_ID, JOBORDER_ID } from '../redux/type';

const chatSDK = new ChatSDK({ debug: false });

const customerSDK = CustomerSDK.init({
    licenseId: LICENSE_ID,
    clientId: CLIENT_ID,
})



class Main extends React.Component {

    constructor(props) {
        super(props);
        this.timerID = "";
        this.messageID = "";
        this.LiveChatWidget = "";
        this.LC_API = "";
        this.step = 0;
        this.profileUpdated = false;
        this.state = {
            allData: {}
        }
    }

    componentDidMount = async () => {
        const { getQualifyQuestionAnswerApi,prechat_data } = this.props
        const { id } = this.props.match.params
        window.__lc.params = [
            { name: 'candidateId', value: id }
        ];
        await getQualifyQuestionAnswerApi('43834');
        await this.authentication();
        let getTearData = await getTearSheet(id)
        let joborderId = getTearData.data[0].jobOrders.data[0].id
        await this._updateData({candidateId:id,joborderId:joborderId})
    }

    _updateData(data){
        const {updatePrechatData} = this.props
        updatePrechatData({
            [CANDIDATE_ID]:data.candidateId,
            [JOBORDER_ID]:data.joborderId
        })
    }

    authentication = async () => {
        await accountsSdk.init({
            client_id: CLIENT_ID,
            onIdentityFetched: async (error, data) => {
                console.log("authentication...", { error, data });
                const access_token = `${data.access_token}`;
                await this.chatInit(access_token);
            }
        });
    }

    chatInit = async (access_token) => {
        await chatSDK.init({ access_token });
        await this.loadWindowObject();
    }

    loadWindowObject = async () => {
        this.LiveChatWidget = window.LiveChatWidget;
        this.LC_API = window.LC_API;
        await this.handleEvent();
    }

    handleEvent = async () => {
        await this.LiveChatWidget.on("form_submitted", this.onFormSubmitted);
        await this.handelQualifyQuestionAnswer()
        await this.on_message();
    }

    onFormSubmitted = (data) => {
        switch (data.type) {
            case 'prechat':
                this.handleProfile();
                break;

            case 'postchat':
                // this.updateProfile(false);
                break;

            default:
                break;
        }
    }

    handelQualifyQuestionAnswer = async () => {
        const { qualify_answer, qualify_question } = this.props
        // let indexOf = qualify_answer.length > 0 ? qualify_answer.indexOf(null) : 0
        var indexOf = 4

        if (indexOf !== -1 && qualify_answer.length > 0) {
            for (let i = 0; i <= indexOf; i++) {
                var res = await this.sendMessage(qualify_question[i])
                if (res) {
                    await this.sendVisitorMessage(qualify_answer[i])
                }
            }

        }
        console.log('indexof  =   >', indexOf)
    }

    handleProfile = async () => {
        const { candidate_id,joborder_id} = this.props
        const customer = this.LiveChatWidget.get('customer_data');
        await getsnoozeCampaign(candidate_id,joborder_id,"chat")
        console.log("customer...", customer);
    }


    handleBotResponse = async () => {

    }

    on_message = async() => {
        const that = this;
        this.LC_API.on_message = async function (data) {
            const {candidate_id,joborder_id} = that.props
            if(data.user_type === "visitor"){
                await getsnoozeCampaign(candidate_id,joborder_id,"chat")
            }
            console.log("on_message...", { data, allData: that.allData, newProfile: that.newProfile })
        }
    }

    sendMessage = (message) => {
        console.log("sendMessage...", message)
        return new Promise((resolve, reject) => {
            chatSDK.sendMessage(this.LC_API.get_chat_id(), message)
                .then(event => resolve(true))
                .catch(error => resolve(false))
        })
    }

    sendVisitorMessage = (message) => {
        const chatId = this.LC_API.get_chat_id()
        const result = customerSDK.sendEvent({
            chatId,
            event: {
                type: 'message',
                text: message,
            },
        }).then(response => { return true })
            .catch(error => { return false })
        return result
    }

    render() {

        return (
            <div></div>
        )
    }
}

export default connect(({ prechat_root }) => {
    let prechatdata = prechat_root && prechat_root.prechat_key
    console.log('state ==== >', prechatdata)
    return ({
        prechat_data: prechatdata.prechat_data ? prechatdata.prechat_data : {},
        qualify_question: prechatdata.qualify_question ? prechatdata.qualify_question : [],
        qualify_answer: prechatdata.qualify_answer ? prechatdata.qualify_answer : [],
        prechat_error: prechatdata.prechat_error ? prechatdata.prechat_error : undefined,

        candidate_id: prechatdata.candidate_id ? prechatdata.candidate_id : "",
        joborder_id: prechatdata.joborder_id ? prechatdata.joborder_id : "",
    })
}, {
    getQualifyQuestionAnswerApi,
    updatePrechatData
})(Main);
