import React, { PureComponent } from 'react'
import './App.css';
import { Provider } from 'react-redux'
import Store from './redux/store'
import Root from './Root';

export default class App extends PureComponent {

  render = () => {
    return (
      <Provider store={Store}>
        <Root />
      </Provider>
    )
  }
}
