export const PRECHAT_ROOT = "prechat_root";
export const PRECHAT_DATA = "prechat_data";
export const PRECHAT_ERROR = "prechat_error";
export const PRECHAT_KEY = "prechat_key"
export const PRECHAT_DATA_UPDATE = "prechat_data_update";
export const PRECHAT_DATA_RESET = "prechat_data_reset";

export const QUALIFY_QUESTION = "qualify_question";
export const QUALIFY_ANSWER = "qualify_answer";
export const USER_ANSWER = "user_answer";

export const CANDIDATE_ID = "candidate_id";
export const JOBORDER_ID = "joborder_id";
export const JOBORDERCUSTOMOBJECT_ID = "jobordercustomobject_id";