import React from 'react'
import { Link } from "react-router-dom";

class Home extends React.Component {
    componentDidMount(){
        document.getElementById("link").click();
    }
    render(){
        return(
                <Link id="link" to="/candidate/48224">Home</Link>
            )
    }
}

export default Home