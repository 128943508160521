import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from './component/home';
import Main from './component/main';

const publicRoutes = () =>
    <BrowserRouter>
        <Switch>
        <Route path="/candidate/:id" component={Main} />
        <Route path="/" component={Home} />
        </Switch>
    </BrowserRouter>

export default publicRoutes;