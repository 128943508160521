import { PRECHAT_ROOT, PRECHAT_KEY, PRECHAT_DATA_UPDATE, PRECHAT_DATA_RESET, PRECHAT_DATA, PRECHAT_ERROR, QUALIFY_QUESTION, QUALIFY_ANSWER } from '../type';
import { getJobOrderCustomObject } from '../../service/api.service'


/** Get joborder customObject */
export const getQualifyQuestionAnswerApi = (id) => {
    return (dispatch, getState) => {
        try {
            getJobOrderCustomObject(id).then((res) => {
                if (res && res.data) {
                    let data = res && res.data && res.data.length > 0 ? res.data[0] : []
                    let question = [data.text5, data.text7, data.textBlock1, data.textBlock2, data.textBlock3, data.textBlock4, data.textBlock5]
                    let answer = [data.text6, data.text8, data.text9, data.int1, data.int2, data.int3, data.int4]

                    question.forEach((qus, Qindex) => {
                        answer.forEach((ans, Aindex) => {
                            if (qus === null && ans === null) {
                                question.splice(Qindex, 1)
                                answer.splice(Aindex, 1)
                            }
                        })
                    })
                    dispatch(updatePrechatData({
                        [PRECHAT_DATA]: data,
                        [QUALIFY_QUESTION]: question,
                        [QUALIFY_ANSWER]: answer
                    }));
                } else {
                    dispatch(updatePrechatData({
                        [PRECHAT_DATA]: []
                    }));
                }
            }).catch(err => {
                dispatch(updatePrechatData({
                    [PRECHAT_ERROR]: err
                }));
            })
        } catch (error) {
            console.log("Update prechat Data ===> error ", error);
        }
    }
}

/** Manage Prechat Data */
export const updatePrechatData = (obj) => {
    return (dispatch, getState) => {
        try {
            const formData = getState()[PRECHAT_ROOT][PRECHAT_KEY];
            const data = Object.assign(formData, obj);

            dispatch(updatePrechatDataState(data));
        } catch (error) {
            console.log("Update prechat Data ===> error ", error);
        }
    }
}

/** Reset Prechat data state */
export const resetPrechatState = () => {
    return (dispatch, getState) => {
        try {
            dispatch({
                type: PRECHAT_DATA_RESET,
                payload: {}
            })
        } catch (error) {
            console.log("Update prechat State ===> error ", error);
        }
    }
}

/** Update Prechat data state */
const updatePrechatDataState = (obj) => {
    return (dispatch, getState) => {
        try {
            const formData = getState()[PRECHAT_ROOT][PRECHAT_KEY];

            dispatch({
                type: PRECHAT_DATA_UPDATE,
                payload: Object.assign(formData, obj)
            })
        } catch (error) {
            console.log("Update prechat Data State ===> error ", error);
        }
    }
}