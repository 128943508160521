// Live Chat Client App
export const CLIENT_ID = "934732a1698c29ffeb5fd95c9d3fb8bc";
export const CLIENT_SECRET = "gEbsrkB8H5TGSmozhggC5kXxURLQrFj3";
export const BASE_URL = "https://bullhorn-backend2.herokuapp.com/bullhorn";
// https://bullhorn-backend2.herokuapp.com/bullhorn

// Live Chat Server App
// export const CLIENT_ID = "a2271d47734a51dc37e8513411311eb7";
// export const CLIENT_SECRET = "WZqcuMrq1rnueVvBeoALmS6Z7a790CMh";

export const LICENSE_ID = 12364428;
export const CHAT_ID = "QL9I2OWLM8";

// Local URLs
// export const SERVER_URL = "http://localhost:3002";
// export const CLIENT_URL = "http://localhost:3001";
// export const USER_URL = "http://localhost:3000";

// Production URLs
export const SERVER_URL = "https://live-chatserver.herokuapp.com";
export const CLIENT_URL = "https://live-chat-client-e3896.web.app";
export const USER_URL = "https://live-chat-user.web.app";

export const STORY_ID = "5fdb4cc55e36f00006dbc503";
export const CLIENT_ACCESS_TOKEN = "k_upABf_p4s0Joemku6zg1_p74IKXBYt";
export const DEVELOPER_ACCESS_TOKEN = "46GZxH8AuWV0uUD1hc3qyVpteLhBQ_kb";

export const LIVE_CHAT_API_URL = "https://api.livechatinc.com/v3.1";
export const CHAT_BOT_API_URL = "https://api.chatbot.com";

// APPs
export const LIVE_SERVER_URL = "https://us-central1-livechat-experiments.cloudfunctions.net/restApi";
export const ACCOUNTS_URL = "https://accounts.livechatinc.com/";
export const LIVE_CHAT_URL = "https://www.livechatinc.com";

// snoozeCampaign
export const SECRET = "986fSyhCj9czUkVG5Ku93sVN";