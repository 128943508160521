import { BASE_URL, SECRET } from '../config'

const headers = new Headers({
    'content-type': 'application/json'
});

export const getJobOrderCustomObject = async (id) => {
    const result = await fetch(`${BASE_URL}/get-jobOrderCustomObject?customObjectId=${id}`, {
        method: 'GET',
        headers
    }).then((response) => { return response.json() }).catch(err => { return err})
    return result;
}

export const getTearSheet = async (id) => {
    const result = await fetch(`${BASE_URL}/get-tearsheet?candidateId=${id}`, {
        method: 'GET',
        headers
    }).then((response) => { return response.json() }).catch(err => { return err})
    return result;
}

export const getJobOrder = async (id) => {
    const result = await fetch(`${BASE_URL}/get-joborder?jobId=${id}`, {
        method: 'GET',
        headers
    }).then((response) => { return response.json() }).catch(err => { return err})
    return result;
}

export const getsnoozeCampaign = async (candidateId,jobId,medium) => {
    const result = await fetch(`https://erestaff.com/services/snoozeCampaign.php?candidateID=${candidateId}&jobOrderID=${jobId}&medium=${medium}&secret=${SECRET}`, {
        method: 'GET'
    }).then((response) => { 
        console.log(response)
        return response.json() 
    }).catch(err => { 
        console.log(err)
        return err
    })
    return result;
}

export const getJobSubmission = async (id) => {
    const result = await fetch(`${BASE_URL}/get-jobsubmission?jobSubmissionId=${id}`, {
        method: 'GET',
        headers
    }).then((response) => { return response.json() }).catch(err => { return err})
    return result;
}

export const createJobSubmission = async (id = null, data) => {
    if (id != null) {
        const result = await fetch(`${BASE_URL}/jobsubmission?jobSubmissionId=${id}`, {
            method: 'POST',
            headers
        }).then((response) => { return response.json() }).catch(err => { return err})
        return result;
    } else {
        const result = await fetch(`${BASE_URL}/jobsubmission`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        }).then((response) => { return response.json() }).catch(err => { return err})
        return result;
    }
}