import React, { Suspense } from 'react'
import Router from './router'


class Root extends  React.Component {
    componentDidMount = () => {
    }

    render() {
       
        return (
            <div>
            <Suspense fallback={<div>Loading...</div>}>
              <Router />
            </Suspense>
          </div>  
        )
    }
}

export default (Root);
