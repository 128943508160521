import { PRECHAT_KEY,PRECHAT_DATA,PRECHAT_ERROR,PRECHAT_DATA_UPDATE,PRECHAT_DATA_RESET,QUALIFY_QUESTION,QUALIFY_ANSWER,USER_ANSWER, CANDIDATE_ID, JOBORDER_ID, JOBORDERCUSTOMOBJECT_ID } from "../type";

const INITIAL_STATE = {
    [PRECHAT_KEY]: {
        [PRECHAT_DATA]: undefined,
        [PRECHAT_ERROR]:undefined,
        [QUALIFY_QUESTION]:[],
        [QUALIFY_ANSWER]:[],
        [USER_ANSWER]:[],
        [CANDIDATE_ID]:"",
        [JOBORDER_ID]:"",
        [JOBORDERCUSTOMOBJECT_ID]:""
    }
};

const Reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRECHAT_DATA_UPDATE:
            return { ...state, [PRECHAT_KEY]: action.payload }
        case PRECHAT_DATA_RESET:
            return {
                ...state,
                [PRECHAT_KEY]: {
                    [PRECHAT_DATA]: undefined,
                    [PRECHAT_ERROR]:undefined,
                    [QUALIFY_QUESTION]:[],
                    [QUALIFY_ANSWER]:[],
                    [USER_ANSWER]:[],
                    [CANDIDATE_ID]:"",
                    [JOBORDER_ID]:"",
                    [JOBORDERCUSTOMOBJECT_ID]:""
                }
            }
        default:
            return state;
    }
}

export default Reducer;